<template>
  <div class="meals">
    <div class="page-header">
      <h1>{{ $t('Meals Management') }} > {{ $t('Meals') }} > {{ $t('Add') }}</h1>
    </div>
    <div class="page-content">
      <div class="content-header">
        <h2>{{ $t('Add Meal') }}</h2>
      </div>
      <MealForm @action="addMeal"/>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import Common from '@/lib/common';
import Menu from '@/lib/menu';
import MealForm from '@/components/menu/MealForm.vue';

export default {
  name: 'AddMeal',
  components: {
    MealForm
  },
  methods:{
    async addMeal(form){
      try{
        const loginInfo = Common.getLoginInfo();
        const mealInfo = Object.assign({}, form);
        const categories = await this.getCategoriesByName(form.categories);
        mealInfo.categories = categories;
        const meal = await Menu.createMeal(this.apiUrl, mealInfo, loginInfo);
        this.$router.push('/meals');
      }catch(err){
        console.log(err);
      }
    },
    async getCategoriesByName(categorieNames){
      const categories = [];
      const loginInfo = Common.getLoginInfo();
      try{
        for(let i = 0 ; i < categorieNames.length; i++){
          const categorieName = categorieNames[i];
          const searchFields = {};
          if(this.langcode === 'zh'){
            searchFields['categorie_name_zh'] = categorieName;
          }else{
            searchFields['categorie_name_en'] = categorieName;
          }
          const categorie = await Menu.searchCategorie(this.apiUrl, searchFields, loginInfo);
          const categorieId = categorie[0].id;
          categories.push(categorieId);
        }
      }catch(err){
        console.log(err);
      }
      return categories;
    },
  },
  computed: mapState({
   langcode: state => state.langcode,
   apiUrl: state => state.apiUrl,
 }),
}
</script>

<style scoped lang="scss">
.content-header{
  display: flex;
  h2{
    flex: 1;
  }
  .action-button{
    margin-right: 20px;
  }
}
</style>
