<template>
  <div class="meal-form">
    <div class="content-tabs">
      <el-tabs class="form-tabs" v-model="activeName" type="card">
        <el-tab-pane :label="$t('Photo')" name="Images">
          <div class="tab-title">
            {{ $t('Upload Meal Photos') }}
          </div>
          <el-upload
          :action="uploadUrl"
          :limit="1"
          :file-list="mealFiles"
          :on-preview="onPreview"
          :on-success="onUploaded"
          :on-remove="onRemove"
          list-type="picture-card">
          <i class="el-icon-plus"></i>
        </el-upload>
      </el-tab-pane>
      <el-tab-pane :label="$t('Meal Details')" name="Meal Details">
        <el-form ref="form" :model="form" label-width="200px" label-position="left">
          <el-form-item :label="$t('Meal Name')" style="max-width: 80%">
            <el-input v-model="form.mealName"></el-input>
          </el-form-item>
          <el-form-item :label="$t('Meal Description')" style="max-width: 80%">
            <el-input
            type="textarea"
            :rows="5"
            v-model="form.mealDescription">
          </el-input>
          </el-form-item>
        </el-form>
      </el-tab-pane>
      <el-tab-pane :label="$t('Categories')" name="Categories">
        <div class="tab-title">
          {{ $t('Categories') }}
        </div>
        <div class="tab-message">
          {{ $t('Meal belongs to these Categories (can choose more than 1)')}} <br/><br/>
        </div>
        <el-form ref="form" :model="form" label-width="200px" label-position="left">
          <el-checkbox-group v-model="form.categories">
            <el-checkbox v-if="langcode == 'zh'" :key="categorie.id" v-for="categorie in categories" :label="categorie.categorie_name_zh"></el-checkbox>
            <el-checkbox v-if="langcode == 'en'" :key="categorie.id" v-for="categorie in categories" :label="categorie.categorie_name_en"></el-checkbox>
          </el-checkbox-group>
        </el-form>
      </el-tab-pane>
      <el-tab-pane :label="$t('Pricing')" name="Pricing">
        <el-form ref="form" :model="form" label-width="100px" label-position="left">
          <el-form-item :label="$t('Regular Price')" style="max-width: 30%">
            <el-input v-model="form.regularPrice">
              <template slot="prepend">HK$</template>
            </el-input>
          </el-form-item>
        </el-form>
      </el-tab-pane>
    </el-tabs>

    <div class="published-checkbox">
      <el-checkbox v-model="form.published">{{ $t('Available') }}</el-checkbox>
    </div>

    <div class="submit-buttons">
      <el-button type="primary" @click="submit"> {{ $t('Submit') }} </el-button>
    </div>
  </div>
</div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import Common from '@/lib/common';
import Menu from '@/lib/menu';

export default {
  name: 'MealForm',
  data(){
    return {
      form: {
        categories: [],
        mealName: '',
        mealDescription: '',
        published: true,
        mealPhoto: '',
        regularPrice: 0,
        restaurant: '',
      },
      uploadUrl: '',
      mealFiles: [],
      categories: [],
      activeName: 'Images',
    }
  },
  mounted(){
    this.uploadUrl = `${this.apiUrl}/meal/file`;
    if(this.targetRestaurant){
      this.form.restaurant = this.targetRestaurant.id;
    }
    this.loadCategories();
  },
  methods:{
    submit(){
      this.$emit('action', this.form);
    },
    onPreview(file){
      window.open(file.url, "_blank");
    },
    onRemove(file){
      this.form.mealFiles = this.form.mealFiles.filter(function(image){
         return file.url !== image;
      });
    },
    async onUploaded(response, file, fileList){
      const fileUrl = response['image_url'];
      this.form.mealPhoto = fileUrl;
    },
    getCategoriesById(categorieIds){
      const categories = this.categories.filter(function(categorie){
        if(categorieIds.includes(categorie.id)){
          return true;
        }
      });
      const categorieNames = [];
      for(let i = 0; i < categories.length; i++){
        if(this.langcode === 'zh'){
          categorieNames.push(categories[i].categorie_name_zh);
        }else{
          categorieNames.push(categories[i].categorie_name_en);
        }
      }
      return categorieNames;
    },
    async loadCategories(){
      const loginInfo = Common.getLoginInfo();
      try{
        const categories = await Menu.loadAllCategories(this.apiUrl, loginInfo);
        this.categories = categories;
      }catch(err){
        console.log(err);
      }
    },
  },
  watch: {
    'currentMeal': async function(){
      const form = {
        categories: [],
        categories: [],
        mealName: this.currentMeal.name,
        mealDescription: this.currentMeal.description,
        published: true,
        mealPhoto: this.currentMeal.image,
        regularPrice: this.currentMeal.regular_price,
        restaurant: this.currentMeal.related_restaurant,
      };

      if(this.currentMeal.status == 1){
        form.published = true;
      }else{
        form.published = false;
      }

      //Load Categories Name
      form.categories = this.getCategoriesById(this.currentMeal.category);
      if(this.currentMeal.image){
        const imageFileName = this.currentMeal.image.substring(this.currentMeal.image.lastIndexOf('/')+1);
        const imageFile = {
          name: imageFileName,
          url: this.currentMeal.image,
        };
        this.mealFiles.push(imageFile);
      }
      this.form = form;
    },
    'langcode': function(){
      this.form.categories = this.getCategoriesById(this.currentMeal.category);
    },
  },
  computed: mapState({
    langcode: state => state.langcode,
    apiUrl: state => state.apiUrl,
    currentMeal: state => state.menu.currentMeal,
    targetRestaurant: state => state.brand.targetRestaurant,
  }),
}
</script>

<style lang="scss">
.form-tabs{
  .el-tabs__content{
    display: block !important;
    padding-left: 24px;
  }
  .tab-title{
    padding-bottom: 10px;
    margin-bottom: 15px;
    color: #008EDD;
  }
  .tab-message{
    margin-bottom: 20px;
    margin-top: 10px;
    font-size: 0.9em;
  }
}
.variation-form{
  margin-top: 20px;
  margin-bottom: 20px;

  .variation-group{
    padding-left: 15px;
    margin-bottom: 20px;

    .el-input{
      margin-right: 20px;
    }
    .tag-input{
      width: 210px;
    }
    .tag-input::placeholder{
      color: #ddd;
    }
    .variation-option{
      margin-right: 20px;
    }
  }
}

.option-form{
  padding-left: 20px;
  .option-index{
    margin-right: 20px;
    position: relative;
    top: 15px;
  }
  .option-group{
    align-items: center;
    margin-bottom: 20px;
    &:last-child{
      margin-bottom: 0;
    }
  }
  .option-name-en{
    margin-right: 30px;
  }
  .option-name-zh{
    margin-right: 30px;
  }
  .option-label{
    margin-bottom: 10px;
  }
  .option-price{
    margin-right: 30px;
  }
  .option-quantity{
    .el-checkbox{
      margin-right: 20px;
    }
  }
}

.variations-list{
  margin-top: 30px;
}
.add-button{
  margin-bottom: 20px;
}

.published-checkbox{
  text-align: right;
  margin-top: 20px;
  margin-bottom: 20px;
}

.submit-buttons{
  text-align: right;
}
</style>
